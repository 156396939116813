import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Metadata, Page } from '~/components/Layout'
import ProductPage from '~/components/Product/Page'

const ProductTemplate = ({ data: { large, medium } }) => (
  <Page>
    <Metadata title={large.name} description={large.shortDescription} />
    <ProductPage product={large} secondaryProduct={medium} />
  </Page>
)

export default ProductTemplate

export const query = graphql`
  query BakingSheetQuery($locale: String) {
    large: contentfulProduct(
      slug: { eq: "large-baking-sheet" }
      node_locale: { eq: $locale }
    ) {
      ...ProductPageFragment
    }
    medium: contentfulProduct(
      slug: { eq: "medium-baking-sheet" }
      node_locale: { eq: $locale }
    ) {
      ...ProductPageFragment
    }
  }
`

ProductTemplate.propTypes = {
  data: PropTypes.shape({
    large: PropTypes.shape({
      name: PropTypes.string,
      shortDescription: PropTypes.string
    }),
    medium: PropTypes.shape({
      name: PropTypes.string,
      shortDescription: PropTypes.string
    })
  })
}
